

import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  emits: ['input'],
  setup(_, ctx) {
    const data = reactive({
      disableComment: {
        get() {
          return _.value
        },
        set() {
          ctx.emit('input', _.value)
        }
      }
    })

    return { ...toRefs(data) }
  }
})
